import React, { Suspense, lazy } from "react";

import TypingEffect from "../TypingEffect";

import "./About.css";

const EarthModel = lazy(() => import("../EarthModel"));

function About() {
  return (
    <div className='about-container'>
      <TypingEffect
        texts={[
          {
            text: "Peter Dyczkowski",
            tag: "h1",
          },
          {
            text: "Software Engineer",
            tag: "h2",
          },
          {
            text: "Focus → Machine Learning, Data Science, Backend Development",
            tag: "h4",
          },
          {
            text: "Top Programming Languages → Python, C#, SQL, TypeScript",
            tag: "h4",
          },
        ]}
      />
      <Suspense fallback={null}>
        <EarthModel />
      </Suspense>
    </div>
  );
}

export default About;
